<template>
  <div>
    <page-title :heading="$t('generic.lang_globalSettings')"
                :subheading="$t('generic.lang_globalSettings')" :icon=icon></page-title>
    <div class="app-main__inner">
      <table-bee-setting-component/>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../Layout/Components/PageTitle.vue";
import TableBeeSettingComponent from "@/components/tableBee/settings/tableBeeSettingComponent";

export default {
  name: "tableBeeGlobalSettingView",
  components: {
    TableBeeSettingComponent,
    PageTitle,
  },

  data: () => ({
    icon: 'pe-7s-config icon-gradient bg-tempting-azure',
  })
}
</script>

