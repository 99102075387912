<template>
    <v-card :loading="loading">
        <v-card-title> {{ $t('generic.lang_otherSettings') }}</v-card-title>
        <v-divider class="ma-0"/>
        <v-card-text>
            <v-container fluid>
                <v-row>
                    <v-col cols="12">
                        <v-select :label="this.$t('generic.lang_notification_sound')" outlined dense hide-details
                                  item-value="id"
                                  item-text="name" :disabled="loading" :loading="loading" v-model="notificationSound"
                                  :items="sounds" return-object>
                            <template v-slot:append-outer>
                                <v-btn class="ma-0 mt-n1" fab x-small color="primary" :disabled="loading">
                                    <v-icon color="white" @click="playSound">
                                        mdi-play
                                    </v-icon>
                                </v-btn>
                            </template>
                        </v-select>
                    </v-col>
                    <!--start: enabled languages -->
                    <v-col cols="12">
                        <v-select item-value="name" item-text="name" dense outlined hide-details
                                  :label="this.$t('tablebee.lang_application_languages')" multiple
                                  v-model="enabled_languages"
                                  :items="this.languages">
                            <template v-slot:item="{item}">
                                <div class="d-flex align-middle">
                                    <country-flag :country="item.flag"/>
                                    <strong class="mx-3 align-middle  my-auto">
                                        {{ item.name }}
                                    </strong>
                                </div>
                            </template>

                        </v-select>
                    </v-col>
                    <!--end: enabled languages -->

                    <v-col cols="12">
                        <v-select item-value="name" item-text="name" dense outlined hide-details
                                  :label="this.$t('tablebee.lang_default_language')"
                                  v-model="default_language"
                                  :items="this.languages">
                            <template v-slot:item="{item}">
                                <div class="d-flex align-middle">
                                    <country-flag :country="item.flag"/>
                                    <strong class="mx-3 align-middle  my-auto">
                                        {{ item.name }}
                                    </strong>
                                </div>
                            </template>

                        </v-select>
                    </v-col>
                    <!--end:  default language -->

                    <!--start: overview start time -->
                    <v-col cols="12">
                        <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                :return-value.sync="time"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="time"
                                              :rules="[  v => !!v ]"
                                              dense :label="$t('generic.lang_time')" outlined
                                              prepend-inner-icon="mdi-clock-time-four-outline"
                                              v-bind="attrs"
                                              v-on="on"
                                />
                            </template>
                            <v-time-picker
                                    v-if="menu"
                                    v-model="time"
                                    full-width
                                    format="24hr"
                                    @click:minute="$refs.menu.save(time)"
                            ></v-time-picker>
                        </v-menu>
                    </v-col>
                    <!--end:  overview start time -->

                    <!--hide product info btn-->
                    <v-col cols="12" sm="6" md="6" lg="6">
                        <v-switch
                                hide-details
                                :label="$t('tablebee.lang_hide_product_details') + ' button'"
                                v-model="hideProductInfo"
                        />
                    </v-col>

                    <!--wiater btn-->
                    <v-col cols="12" sm="6" md="6" lg="6">
                        <v-switch
                                hide-details
                                :value="false"
                                :label="this.$t('tablebee.lang_activate_waiter_btn')"
                                v-model="enableWaiterButton"
                        />
                    </v-col>

                    <!--enable/disable default product info-->
                    <v-col cols="12" sm="6" md="6" lg="6">
                        <v-checkbox
                                hide-details
                                :label="this.$t('tablebee.lang_show_product_info')"
                                v-model="showStandardProductInfo"
                        />
                    </v-col>

                    <!--enable / diable product allergens-->
                    <v-col cols="12" sm="6" md="6" lg="6">
                        <v-checkbox hide-details :label="this.$t('tablebee.lang_show_allergens')"
                                    v-model="showAlergens"/>
                    </v-col>

                    <!--enable / disable ordering as groups-->
                    <v-col cols="12" sm="6" md="6" lg="6">
                        <v-switch
                                hide-details
                                :label="this.$t('tablebee.lang_enable_ordering_as_group')"
                                v-model="enable_order_as_group"
                        />
                    </v-col>

                    <!--enable / disable ordering as groups-->
                    <v-col cols="12" sm="6" md="6" lg="6">
                        <v-switch
                                hide-details
                                :value="true"
                                :label="this.$t('tablebee.lang_enable_read_only')"
                                v-model="tb_enable_read_only"
                        />
                    </v-col>

                    <!--show / hide product description in the product info dialog-->
                    <v-col cols="12" sm="6" md="6" lg="6">
                        <v-checkbox
                                hide-details
                                :label="this.$t('tablebee.lang_show_product_description')"
                                v-model="showProductInfo"
                        />
                    </v-col>

                    <!-- Enable Sms informing -->
                    <v-col cols="12" sm="6" md="6" lg="6">
                        <v-checkbox
                                v-if="this.$store.getters['permissions/checkModule'](70)"
                                hide-details
                                :label="this.$t('tablebee.lang_sendSMSWhenOrderReady')"
                                v-model="tb_enableSMSSending"
                        />
                    </v-col>

                    <!--start: privacy translation-->
                    <v-col cols="12">
                        <b-tabs>
                            <b-tab active>
                                <template #title>
                                    <v-btn text class="ma-0">
                                        <country-flag country="DE"/>
                                    </v-btn>
                                </template>
                                <b-card :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">
                                    <v-row no-gutters>
                                        <v-col class="" cols="12">
                                            <v-subheader
                                            ><strong>{{ this.$t('tablebee.lang_standart_product_info') }}</strong>
                                            </v-subheader
                                            >
                                            <quill-editor
                                                    id="privacy_editor"
                                                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                                    :options="quillOptions"
                                                    @focus="showTouchKeyboard"
                                                    output="html"
                                                    class="mb-4"
                                                    v-model="standardProductInfo_de"
                                            >
                                            </quill-editor>
                                        </v-col>
                                    </v-row>
                                </b-card>
                            </b-tab>

                            <b-tab>
                                <template #title>
                                    <v-btn text class="ma-0">
                                        <country-flag country="GB"/>
                                    </v-btn>
                                </template>
                                <b-card :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">
                                    <v-row no-gutters>
                                        <v-col class="" cols="12">
                                            <v-subheader
                                            ><strong>{{ this.$t('tablebee.lang_standart_product_info') }}</strong>
                                            </v-subheader
                                            >
                                            <quill-editor
                                                    id="privacy_editor_2"
                                                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                                    :options="quillOptions"
                                                    @focus="showTouchKeyboard"
                                                    output="html"
                                                    class="mb-4"
                                                    v-model="standardProductInfo_en"
                                            >
                                            </quill-editor>
                                        </v-col>
                                    </v-row>
                                </b-card>
                            </b-tab>

                            <b-tab>
                                <template #title>
                                    <v-btn text class="ma-0">
                                        <country-flag country="FR"/>
                                    </v-btn>
                                </template>
                                <b-card :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">
                                    <v-row no-gutters>
                                        <v-col class="" cols="12">
                                            <v-subheader
                                            ><strong>{{ this.$t('tablebee.lang_standart_product_info') }}</strong>
                                            </v-subheader>
                                            <quill-editor
                                                    id="privacy_editor_3"
                                                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                                    :options="quillOptions"
                                                    @focus="showTouchKeyboard"
                                                    output="html"
                                                    class="mb-4"
                                                    v-model="standardProductInfo_fr"
                                            >
                                            </quill-editor>
                                        </v-col>
                                    </v-row>
                                </b-card>
                            </b-tab>

                            <b-tab>
                                <template #title>
                                    <v-btn text class="ma-0">
                                        <country-flag country="SA"/>
                                    </v-btn>
                                </template>
                                <b-card :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">
                                    <v-row no-gutters>
                                        <v-col class="" cols="12">
                                            <v-subheader
                                            ><strong>{{ this.$t('tablebee.lang_standart_product_info') }}</strong>
                                            </v-subheader
                                            >
                                            <quill-editor
                                                    id="privacy_editor_4"
                                                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                                    :options="quillOptions"
                                                    @focus="showTouchKeyboard"
                                                    output="html"
                                                    class="mb-4"
                                                    v-model="standardProductInfo_ar"
                                            >
                                            </quill-editor>
                                        </v-col>
                                    </v-row>
                                </b-card>
                            </b-tab>
                        </b-tabs>
                    </v-col>
                    <!--start: privacy translation-->
                </v-row>
            </v-container>
        </v-card-text>
        <v-divider class="ma-0"/>
        <v-card-actions class="pr-6">
            <v-row no-gutters>
                <v-col cols="12" align="end">
                    <v-btn large :loading="loading" @click="update" color="success">
                        {{ $t("generic.lang_save") }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
        <div id="onScreenKeyboardDiv">
            <vue-touch-keyboard
                    :accept="hideTouchKeyboard"
                    :cancel="hideTouchKeyboard"
                    :defaultKeySet="touchKeyboard.keySet"
                    :input="touchKeyboard.input"
                    :layout="touchKeyboard.layout"
                    :options="touchKeyboard.options"
                    class="internalWidth"
                    id="onScreenKeyboard"
                    v-if="touchKeyboard.visible"
            />
        </div>
    </v-card>
</template>

<script>
import mixin from "../../../mixins/KeyboardMixIns";
import {Events} from "@/plugins/events";
import {ENDPOINTS} from "@/config";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import {quillEditor} from "vue-quill-editor";
import CountryFlag from "vue-country-flag";

export default {
    name: "tableBeeOtherSettingsComponent",
    components: {
        quillEditor,
        CountryFlag,
    },
    mixins: [mixin],
    data() {
        return {
            tb_enable_read_only: false,
            enabled_languages: null,
            default_language: false,
            menu: false,
            time: null,
            notificationSound: 0,
            sounds: [
                {
                    id: 0,
                    name: this.$t('generic.lang_none'),
                    src: '',
                },
                {
                    id: 1,
                    name: "mixkit-bell-notification-933",
                    src: require("@/assets/sounds/mixkit-bell-notification-933.wav"),
                },
                {
                    id: 2,
                    name: "mixkit-clear-announce-tones-2861",
                    src: require("@/assets/sounds/mixkit-clear-announce-tones-2861.wav"),
                },
                {
                    id: 3,
                    name: "mixkit-correct-answer-reward-952",
                    src: require("@/assets/sounds/mixkit-correct-answer-reward-952.wav"),
                },
                {
                    id: 4,
                    name: "mixkit-doorbell-single-press-333",
                    src: require("@/assets/sounds/mixkit-doorbell-single-press-333.wav"),
                },
                {
                    id: 5,
                    name: "mixkit-happy-bells-notification-937",
                    src: require("@/assets/sounds/mixkit-happy-bells-notification-937.wav"),
                },
                {
                    id: 6,
                    name: "mixkit-magic-notification-ring-2344",
                    src: require("@/assets/sounds/mixkit-magic-notification-ring-2344.wav"),
                },
                {
                    id: 7,
                    name: "mixkit-musical-alert-notification-2309",
                    src: require("@/assets/sounds/mixkit-musical-alert-notification-2309.wav"),
                },
                {
                    id: 8,
                    name: "mixkit-sci-fi-reject-notification-896",
                    src: require("@/assets/sounds/mixkit-sci-fi-reject-notification-896.wav"),
                },
                {
                    id: 9,
                    name: "notification",
                    src: require("@/assets/sounds/notification.mp3"),
                },
                {
                    id: 10,
                    name: "notification2",
                    src: require("@/assets/sounds/notification2.mp3"),
                },
                {
                    id: 11,
                    name: "notification3",
                    src: require("@/assets/sounds/notification3.mp3"),
                },
                {
                    id: 12,
                    name: "notification4",
                    src: require("@/assets/sounds/notification4.mp3"),
                },
                {
                    id: 13,
                    name: "notification5",
                    src: require("@/assets/sounds/notification5.mp3"),
                },
            ],
            languages: [
                {
                    name: "De",
                    flag: "de"
                },
                {
                    name: "En",
                    flag: "GB"
                },
                {
                    name: "Fr",
                    flag: "fr"
                },
                {
                    name: "Ar",
                    flag: "sa"
                }
            ],
            ENDPOINTS,
            loading: false,
            pickup: false,
            delivery: false,
            maintenance: false,
            hideProductInfo: false,
            showAlergens: false,
            showProductInfo: false,
            showStandardProductInfo: false,
            standardProductInfo_de: null,
            standardProductInfo_en: null,
            standardProductInfo_fr: null,
            standardProductInfo_ar: null,
            enableWaiterButton: null,
            enable_order_as_group: null,
            tb_enableSMSSending: false,
            quillOptions: {
                modules: {
                    toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        [{header: [1, 2, 3, 4, 5, 6, false]}],
                        [{align: []}],
                        [{font: []}],
                        [
                            {list: "ordered"},
                            {list: "bullet"},
                            {indent: "-1"},
                            {indent: "+1"},
                        ],
                    ],
                },
            },
        };
    },
    computed: {},
    methods: {
        getData() {
            this.loading = true;
            this.axios
                .post(ENDPOINTS.TABLEBEE.SETTINGS.OTHERS.GET)
                .then((res) => {
                    this.hideProductInfo = res.data.data.hideProductInfo;
                    this.showAlergens = res.data.data.showAlergens;
                    this.showProductInfo = res.data.data.showProductInfo;
                    this.showStandardProductInfo = res.data.data.showStandardProductInfo;
                    this.standardProductInfo_de = res.data.data.standardProductInfo_de;
                    this.standardProductInfo_en = res.data.data.standardProductInfo_en;
                    this.standardProductInfo_fr = res.data.data.standardProductInfo_fr;
                    this.standardProductInfo_ar = res.data.data.standardProductInfo_ar;
                    this.enableWaiterButton = !res.data.data.hideWaiterButton;
                    this.enable_order_as_group = res.data.data.enable_order_as_group;
                    this.tb_enable_read_only = res.data.data.tb_enable_read_only;
                    this.enabled_languages = res.data.data.enabled_languages;
                    this.default_language = res.data.data.default_language;
                    let sound = this.sounds.find(s => s.id === Number(res.data.data.notificationSoundID))
                    this.notificationSound = sound ? sound : 0;
                    this.time = res.data.data.statisticsStartTime;
                    this.tb_enableSMSSending = res.data.data.tb_enableSMSSending;
                })
                .catch((err) => {
                    Events.$emit("showSnackbar", {
                        color: "error",
                        message: err.message,
                    });
                })
                .finally((fin) => {
                    this.loading = false;
                });
        },
        update() {
            this.loading = true;
            this.axios
                .post(ENDPOINTS.TABLEBEE.SETTINGS.OTHERS.UPDATE, {
                    hideProductInfo: this.hideProductInfo,
                    showAlergens: this.showAlergens,
                    showProductInfo: this.showProductInfo,
                    showStandardProductInfo: this.showStandardProductInfo,
                    standardProductInfo_de: this.standardProductInfo_de,
                    standardProductInfo_en: this.standardProductInfo_en,
                    standardProductInfo_fr: this.standardProductInfo_fr,
                    standardProductInfo_ar: this.standardProductInfo_ar,
                    hideWaiterButton: !this.enableWaiterButton,
                    enable_order_as_group: this.enable_order_as_group,
                    enabled_languages: this.enabled_languages,
                    default_language: this.default_language,
                    notificationSoundID: this.notificationSound ? this.notificationSound.id : 0,
                    statisticsStartTime: this.time,
                    tb_enableSMSSending: this.tb_enableSMSSending,
                    tb_enable_read_only: this.tb_enable_read_only
                })
                .then((res) => {
                    if (res.data.status === "SUCCESS") {
                        Events.$emit("showSnackbar", {
                            color: "success",
                            message: this.$t('generic.lang_dataSaved'),
                        });
                    } else {
                        Events.$emit("showSnackbar", {
                            color: "error",
                            message: res.data.msg || res.data.status,
                        });
                    }
                })
                .catch((err) => {
                    Events.$emit("showSnackbar", {
                        color: "error",
                        message: err.message,
                    });
                })
                .finally((fin) => {
                    this.loading = false;
                });
        },
        playSound() {
            if (this.notificationSound) {
                let audio = new Audio(this.notificationSound.src);
                audio.play();
            } else {
                Events.$emit("showSnackbar", {
                    color: "warning",
                    message: this.$t('generic.lang_pleaseSelectSoundToPlay')
                });
            }
        }
    },
    mounted() {
        this.getData();
    },
};
</script>

<style scoped></style>
